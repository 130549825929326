
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    return {
      navbarBrand: "ROQG",
      pages: [
        {
          name: "Home",
          path: "/",
        },
        {
          name: "Aiuto",
          path: "/help",
        },
        {
          name: "Impostazioni",
          path: "/settings",
        },
      ],
    };
  },
});
